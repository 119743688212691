export const LAST_VISITED_PINCODE = "lastVisitedPincode";
export const LAST_VISITED_ADDRESS = "lastVisitedAddress";
export const LAST_VISITED_COUNTRIES = "lastVisitedCountries";
export const SELECTED_INTERNATIONAL_CITY = "selectedIntlCity";
export const LAST_VISITED_INTERNATIONAL_LOCATION = "lastVisitedIntlLocation";
export const LAST_VISITED_INTERNATIONAL_CITY = "lastVisitedIntlCity";
export const PF_COOKIE = "pf";
export const INTERNATIONAL_PF_COOKIE = "intlPf";
export const SELECTED_SAVED_ADDRESS = "selectedSavedAddress";
export const SELECTED_SAVED_ADDRESS_USERNAME = "selectedSavedAddressUserName";
