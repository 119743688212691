import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import CustomImage from "../../atoms/customImage";
import { lockBody, unlockBody } from "../../../utils/document";
import { BOTTOM } from "../../../constants/common/common";

/**
 * Custom Drawer Component.
 * @param {Object} props - Component props.
 * @param {string} props.position - Position of the drawer ('bottom', 'right').
 * @param {boolean} props.isOpen - Boolean indicating whether the drawer is open or closed.
 * @param {function} props.onDrawerClose - Function to handle the closing of the drawer.
 * @param {boolean} props.isBackdropOff - Boolean to handle backdrop click to close the drawer.
 * @param {boolean} props.isDrawerCustomStyles - Boolean to customize and add conditional styling.
 * @param {React.ReactNode} props.children - Children elements to be rendered inside the drawer.
 * @param {string} props.styles - custom style of drawer.
 * @param {string} props.childContainerClass - Class for custom styling the children.
 * @param {boolean} props.isHideCloseBtn - Boolean to hide the drawer close button.
 * @param {boolean} props.isShowTopLine - Boolean to show the top line of drawer to on touch move.
 * @param {boolean} props.isHideOverlay - Boolean to hide the drawer main container.
 * @param {boolean} props.isPreventUnlockBody - Boolean to prevent the unlockbody when closes the drawer.
 * @returns {JSX.Element} Drawer component.
 */
const Drawer = ({
  position,
  children,
  isOpen,
  isBackdropOff = true,
  onDrawerClose,
  styles,
  isDrawerCustomStyles = true,
  childContainerClass = "",
  isHideCloseBtn = false,
  isShowTopLine = false,
  isHideOverlay = true,
  isPreventUnlockBody = false,
  isCleanUp = true,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const drawerRef = useRef(null);
  const diffPercentageRef = useRef(null);
  const dragStartY = useRef(null);
  const posRef = useRef(null);
  const dragThreshold = 30;
  const positionList = {
    bottom: {
      classes: `flex flex-col justify-center fixed bottom-0 left-0 right-0 bg-white-900 shadow-inner ${isDrawerCustomStyles ? "rounded-t-24 p-16" : ""}  px-0 ${styles}`,
      closeButton: "flex items-center justify-center absolute -top-40 left-[50%] translate-x-[-50%] w-32 h-32",
      transform: showOverlay ? "translateY(0)" : "translateY(100%)",
    },
    right: {
      classes: `fixed top-0 right-0 h-screen w-[29%] bg-white-900 shadow-lg p-0 ${styles}`,
      closeButton: "-ml-64 flex items-center justify-center absolute mt-16 w-32 h-32",
      transform: showOverlay ? "translateX(0)" : "translateX(100%)",
    },
  };

  const { classes, closeButton, transform } = positionList[position];

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        lockBody();
        setShowOverlay(true);
      } else {
        setShowOverlay(false);
      }
    });
    return () => {
      if (isCleanUp) {
        unlockBody();
      }
    };
  }, [isOpen, isCleanUp]);

  /**
   * To close the drawer.
   */
  const hideDrawerOverlay = (isOutsideClick = false) => {
    if (isHideOverlay) {
      setShowOverlay(false);
    }
    setTimeout(() => {
      onDrawerClose(isOutsideClick);
    }, 300);
  };

  /**
   * Handle closing the drawer.
   * @param {Object} event - Event object.
   */
  const onClose = (event) => {
    event.stopPropagation();
    if (isBackdropOff) {
      if (!isPreventUnlockBody) {
        unlockBody();
      }
      hideDrawerOverlay(false);
    }
  };

  /**
   * Handle backdrop click to close the drawer.
   */
  const onBackDropClick = () => {
    if (isOpen && isBackdropOff) {
      if (!isPreventUnlockBody) {
        unlockBody();
      }
      hideDrawerOverlay(true);
    }
  };

  /**
   * This function will update the position of drawer on touch.
   * @param {*} clientY
   */
  const updatePosition = (clientY) => {
    const slideDiff = clientY - dragStartY.current;
    const isPositiveDir = slideDiff > 0;

    if (drawerRef.current) {
      const diffPer = (Math.abs(slideDiff) / drawerRef.current.offsetWidth) * 100;
      if (isPositiveDir) {
        if (diffPer <= 100) {
          diffPercentageRef.current = diffPer;
          drawerRef.current.style.transform =
            position === BOTTOM ? `translateY(${diffPer}%)` : `translateX(${diffPer}%)`;
        }
      }
    }
  };

  /**
   * This function will update the position of drawer on touch.
   * @param {*} evt
   */
  const onTouchMove = (evt) => {
    const touch = evt.targetTouches[0];
    const pos = touch.clientY - dragStartY.current;
    posRef.current = pos;
    if (drawerRef.current) {
      drawerRef.current.style.transitionProperty = "none";
    }
    updatePosition(touch.clientY);
  };

  /**
   * It will trigger when we start the touch on the top line of drawer.
   * @param {*} e
   */
  const onHandleTouchStart = (e) => {
    dragStartY.current = e.targetTouches[0].clientY;
    diffPercentageRef.current = 0;

    window.addEventListener("touchmove", onTouchMove);
  };

  /**
   * This function will close the drawer when it reached at certain position.
   */
  const onDragEnd = () => {
    if (drawerRef.current) {
      drawerRef.current.style.transitionProperty = "transform";
    }
    if (posRef.current > 0 && diffPercentageRef.current > dragThreshold) {
      if (drawerRef.current) {
        drawerRef.current.style.transform = position === BOTTOM ? `translateY(100%)` : `translateX(100%)`;
      }
      setTimeout(() => {
        hideDrawerOverlay(false);
      }, 0);
    } else if (drawerRef.current) {
      drawerRef.current.style.transform = position === BOTTOM ? `translateY(0)` : `translateX(0)`;
    }
    window.removeEventListener("touchmove", onTouchMove);
  };

  return (
    isOpen && (
      <div id={`${position}_drawer`} className="fixed z-[999]" data-testid="drawer">
        {isOpen && (
          <button
            onClick={onBackDropClick}
            id={`${position}_drawer_btn`}
            className="bg-white fixed inset-0 h-full w-full bg-black-900 bg-opacity-60"
            data-testid="backdrop"
          />
        )}
        <div
          style={{ transform, transition: "transform 0.2s ease-in-out, top 0.2s ease-in-out" }}
          className={classes}
          ref={drawerRef}
        >
          {isOpen && isDrawerCustomStyles && !isHideCloseBtn && (
            <button
              onClick={onClose}
              id={`${position}_drawer_btn`}
              className={`h-20 w-20 rounded-full ${closeButton}`}
              data-testid="close-button"
            >
              <CustomImage
                src="/icons/drawer-close.svg"
                alt="drawer-close"
                width={60}
                height={60}
                sizes="10vw"
                isPriority={false}
              />
            </button>
          )}
          {isShowTopLine && (
            <div
              className="flex items-center justify-center pb-8"
              onTouchStart={onHandleTouchStart}
              onTouchEnd={onDragEnd}
              data-testid="top-line"
            >
              <i className="h-[4px] w-40 rounded-xl bg-grey-300" />
            </div>
          )}
          <div className={`relative h-full w-full ${childContainerClass ? childContainerClass : ""}`}>{children}</div>
        </div>
      </div>
    )
  );
};

Drawer.propTypes = {
  position: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  styles: PropTypes.string,
  isDrawerCustomStyles: PropTypes.bool,
  isBackdropOff: PropTypes.bool,
  childContainerClass: PropTypes.string,
  isHideCloseBtn: PropTypes.bool,
  isPreventClose: PropTypes.bool,
  isShowTopLine: PropTypes.bool,
  isHideOverlay: PropTypes.bool,
  isPreventUnlockBody: PropTypes.bool,
  isCleanUp: PropTypes.bool,
};

export default Drawer;
