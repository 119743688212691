/* eslint-disable max-lines */
export const desktopIndiaConfig = {
  menuItems: [
    { href: "/", name: "Home India" },
    { href: "/about", name: "About India" },
  ],

  currency: "INR",
  countryList: [
    { iconUrl: "/icons/countries-flags/usa.svg", countryCurrency: "USD", label: "" },
    { iconUrl: "/icons/countries-flags/thailand.svg", countryCurrency: "THB", label: "" },
    { iconUrl: "/icons/countries-flags/singapore.svg", countryCurrency: "SGD", label: "" },
    { iconUrl: "/icons/countries-flags/qatar.svg", countryCurrency: "QAR", label: "" },
    { iconUrl: "/icons/countries-flags/new-zealand.svg", countryCurrency: "NZD", label: "" },
    { iconUrl: "/icons/countries-flags/malaysia.svg", countryCurrency: "MYR", label: "" },
    { iconUrl: "/icons/countries-flags/india.svg", countryCurrency: "INR", label: "" },
    { iconUrl: "/icons/countries-flags/uk.svg", countryCurrency: "GBP", label: "" },
    { iconUrl: "/icons/countries-flags/germany.svg", countryCurrency: "EUR", label: "" },
    { iconUrl: "/icons/countries-flags/canada.svg", countryCurrency: "CAD", label: "" },
    { iconUrl: "/icons/countries-flags/australia.svg", countryCurrency: "AUD", label: "" },
    { iconUrl: "/icons/countries-flags/uae.svg", countryCurrency: "AED", label: "" },
  ],
  moreInfoDropDown: [
    {
      iconUrl: "/icons/reminder.svg",
      text: "Reminder",
      label: "",
      landingUrl: "/account/reminder",
    },
    {
      iconUrl: "/icons/FNP-franchise.svg",
      text: "Start an FNP Franchise",
      label: "",
      landingUrl: "/info/franchise-programme?promo=franchise_enquiry",
    },
    {
      iconUrl: "/icons/become-a-vendor.svg",
      text: "Become a Vendor",
      label: "",
      landingUrl: "/info/become-a-partner?promo=partner_enquiry",
    },
    {
      iconUrl: "/icons/balloon-birthday-wedding-decor.svg",
      text: "Birthday / Wedding Decor",
      label: "",
      landingUrl: "/services/weddings-n-events?promo=wedding_enquiry",
    },
    { iconUrl: "/icons/media.svg", text: "Media", label: "" },
  ],
  accountDropDown: [
    { iconUrl: "/icons/login-register.svg", text: "Login/Register", label: "" },
    { iconUrl: "/icons/my-account-user.svg", text: "My Account", label: "", landingUrl: "/account/my-account" },
    { iconUrl: "/icons/brownie-Points.svg", text: "fnpCash", label: "", landingUrl: "/account/wallet-details" },
    { iconUrl: "/icons/my-order.svg", text: "My Orders", label: "", landingUrl: "/account/my-orders" },
    { iconUrl: "/icons/contact-us.svg", text: "Contact Us", label: "", landingUrl: "/info/contact-us" },
    { iconUrl: "/icons/faq-message-question.svg", text: "FAQs", label: "", landingUrl: "/info/faq" },
  ],

  loggedInAccountDropDown: [{ iconUrl: "/icons/logout.svg", text: "Logout", label: "" }],
  footerInfo: [
    {
      id: "worldwide-delivery",
      heading: "Worldwide Delivery",
      subheading: "We deliver gifts to over 70 countries",
    },
    {
      id: "safe-and-secure-payments",
      heading: "100% Safe & Secure Payments",
      subheading: "Pay using secure payment methods",
    },
    {
      id: "dedicated-help-center",
      heading: "Dedicated Help Center",
      subheading1: "Call us +91 9212422000 | 8:00AM-10:30PM",
      subheading2: "Chat With Us",
    },
  ],
  socialIcons: [
    {
      href: "https://www.facebook.com/fnpind",
      id: "facebook",
      ariaLabel: "Visit our facebook page",
    },
    { href: "https://twitter.com/fnp_ind", id: "twitter", ariaLabel: "Visit our twitter page" },
    {
      href: "https://www.instagram.com/fernsnpetalsindia/?hl=en",
      id: "instagram",
      ariaLabel: "Visit our instagram page",
    },
    { href: "https://www.linkedin.com/company/fnp-world", id: "linkedin", ariaLabel: "Visit our linkedin page" },
  ],

  footerBodyLinks: [
    {
      title: "Policy Info",
      links: [
        { path: "/info/terms-and-conditions", linkName: "Terms & Conditions" },
        { path: "/info/privacy-policy", linkName: "Privacy Policy" },
        { path: "/info/terms-of-use", linkName: "Terms of Use" },
        { path: "/info/disclaimer", linkName: "Disclaimer" },
      ],
    },
    {
      title: "About Company",
      links: [
        { path: "/info/about-us", linkName: "About Us" },
        { path: "/info/fnp-team", linkName: "FNP Team" },
        { path: "/info/careers", linkName: "Careers" },
        { path: "/info/testimonials", linkName: "Testimonials" },
        { path: "/info/news", linkName: "News Room" },
      ],
    },
    {
      title: "FNP business",
      links: [
        { path: "/decoration-services", linkName: "Decoration Services" },
        { path: "/corporate", linkName: "Corporate Service" },
        { path: "/info/affiliate-program", linkName: "Affiliate Program" },
        { path: "/info/retail-stores", linkName: "Retails Stores" },
        { path: "/info/franchise-programme", linkName: "Franchise" },
      ],
    },
    {
      title: "Need Help ?",
      links: [
        { path: "/info/contact-us", linkName: "Contact Us" },
        { path: "/info/faq", linkName: "FAQs" },
      ],
    },
    {
      title: " Useful Links",
      links: [
        { path: "/quotes/wishes-n-messages", linkName: "Quotes N Wishes" },
        { path: "/article/flower-astrology", linkName: "Flower Astrology" },
        { path: "/info/articles-hub", linkName: "Article Hub" },
        { path: "/article/care-guide", linkName: "Care Guide" },
        { path: "/blog/", linkName: "Blog" },
      ],
    },
  ],
  paymentGatewayItems: [
    { id: "visa" },
    { id: "mastercard" },
    { id: "rupay" },
    { id: "diners-club" },
    { id: "american-express" },
  ],
  startYear: "1994",
  isSmartLockEnabled: true,
  isOmnitureEnabled: true,
  isCdpEnabled: true,
  popularMobileCodes: [
    "+91 IND",
    "+1 USA",
    "+1-284 VGB",
    "+61 AUS",
    "+65 SGP",
    "+974 QAT",
    "+64 NZL",
    "+60 MYS",
    "+1 CAN",
    "+971 ARE",
  ],

  animationTime: 300,
  defaultCountry: {
    countryName: "India",
    countryCategoryID: "gifts",
    catelogID: "india",
    countryId: "IND",
    logo: "https://m-i1.fnp.com/assets/images/custom/country-flags/India.png",
    webLogo: "https://m-i1.fnp.com/assets/images/custom/country-flags/india.svg",
    countryCode: "91",
    countryCurrency: "INR",
  },
};

export const fnpBrandsData = {
  fnpBrands: [
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/Fnpvenues.png",
      brandUrl: "https://www.fnpvenues.com/",
      altTag: "Fnpvenues",
      width: 119,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/FNP-weddings.png",
      brandUrl: "https://www.fnpweddings.com/",
      altTag: "FNP Weddings",
      width: 232,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/FNP-water.png",
      brandUrl: "#",
      altTag: "FNP Water",
      width: 108,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/WDH.png",
      brandUrl: "#",
      altTag: "WDH",
      width: 80,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/FNP-media.png",
      brandUrl: "#",
      altTag: "FNP Media",
      width: 151,
      height: 44,
    },
    {
      imageUrl: "https://www.fnp.com/assets/images/custom/fnp-brands/Flagship.png",
      brandUrl: "#",
      altTag: "Flagship",
      width: 69,
      height: 44,
    },
  ],
  csr: {
    content: [
      "Company Name: FNP E Retail Private Limited | CIN: U52100DL2021PTC376303 | Regd. Office Address: FNP Estate, Ashram Marg, Mandi Road, Gadaipur, New Delhi-110030",
      "Telephone No.: +91-11-26802680 | E-mail: help@fnp.com | Grievance Resolution Officer Name: Ms. Preeti Bindra | Contact No.: +91 9212422000 / 9755-248-248",
    ],
    linkText: "Corporate Social Responsibility (CSR) Policy",
    link: "/info/csr-policy",
  },
};

export const dnsPrefetchDomains = [
  "//assets.adobedtm.com",
  "//sslwidget.criteo.com",
  "//gum.criteo.com",
  "//asia.creativecdn.com",
  "//stats.g.doubleclick.net",
  "//www.google.com",
  "//www.googletagmanager.com",
  "//www.google-analytics.com",
  "//googleads.g.doubleclick.net",
];
